'use client';

import { ReactNode } from 'react';
import { Spinner } from '../components/Spinner/Spinner';

export default function Loading(): ReactNode {
    return (
        <div>
            <Spinner />
        </div>
    );
}
