'use client';

import { useMsalAccount } from '@/contexts/TokenContext/useMsalAccount';
import { useJsonEqualState } from '@/hooks/useJsonEqualState';
import { AccountInfo } from '@azure/msal-browser';
import { FSApi } from '@fullstory/snippet';
import { ReactNode, useEffect } from 'react';
import { FullStoryIdentity } from './FullStoryIdentity';
import { useFullStory } from './FullStoryWrapper';

export function logUserToFullStory(identity: FullStoryIdentity, fullStory: FSApi) {
    // No data
    if (!identity) {
        return;
    }

    // Full Story isn't active / ready
    if (!fullStory) {
        return;
    }

    // Log user information to FullStory
    fullStory('setIdentity', identity);
}

function getFullStoryIdentity(account: AccountInfo): FullStoryIdentity {
    const uid = account?.homeAccountId;
    if (!uid) {
        return undefined;
    }

    const identity: FullStoryIdentity = {
        uid: uid,
        properties: {
            displayName: account.name,
            email: account.username,
        },
    };

    return identity;
}

export function FullStoryUserLogger(): ReactNode {
    // Get the account from MSAL
    const account = useMsalAccount();
    const [identity, setIdentity] = useJsonEqualState<FullStoryIdentity>();
    const fullStory = useFullStory();

    // Keep track of the parts of the account info that we need to send to FullStory
    useEffect(() => {
        const newIdentity = getFullStoryIdentity(account);
        // setIdentity(newIdentity);
    }, [account, setIdentity]);

    // If the parts we care about change, send that fo FullStory
    useEffect(() => {
        // Wait for FullStory to initialize
        if (!fullStory || !identity) {
            return;
        }
        // logUserToFullStory(identity, fullStory);
    }, [identity, fullStory]);

    // Just return as an empty element
    return null;
}
