import AuthenticatedJsonContent from '@/app/(authenticated)/content/jsonGet/template';
import { AppConfig } from '@/common/interfaces/containers/config/AppConfig';
import { getAppConfigURL } from '@/hooks/api/getAppConfigURL';
import { ReactNode, useState } from 'react';

export function BriefModeDetails(): ReactNode {
    const appConfigURL = getAppConfigURL();
    const [appConfig, setAppConfig] = useState<AppConfig>();

    // Display the details of the mode in settings or the current one
    const settings = appConfig?.settings;
    const mode = settings?.mode;
    const modeDetails = appConfig?.modesLookup[mode];

    return (
        <AuthenticatedJsonContent url={appConfigURL} setData={setAppConfig}>
            {modeDetails?.description || <>&nbsp;</>}
        </AuthenticatedJsonContent>
    );
}
